<!--
 * @Descripttion: 
 * @Author: liang
 * @Date: 2024-08-27 10:40:30
 * @LastEditors: zhangziyu
 * @LastEditTime: 2024-09-26 11:01:57
-->
<!--
 * @Descripttion: 
 * @Author: liang
 * @Date: 2024-08-27 10:40:30
 * @LastEditors: liang
 * @LastEditTime: 2024-08-27 16:36:06
-->
<template>
  <div class="invite" style="overflow: hidden">
    <!-- <div class="invite-bg">
      <img
        src="@/assets/img/invite/open-beta-invite_bg.png"
        class="invite-bg-img"
      />
    </div> -->
    <div class="content">
      <div style="text-align: center; padding: 0 20px 0 8px">
        <img src="@/assets/img/invite/new-get-money.png" style="width: 100%" />
      </div>
      <div style="padding-top: 13px; padding-bottom: 13px">
        <InviteLogin
          :inviterMobile="inviterMobile"
          :invitationMethod="invitationMethod"
        ></InviteLogin>
      </div>
      <!-- <div class="invite-way" style="padding-bottom: 10px" v-else>
        <InviteWay></InviteWay>
      </div> -->
      <!-- <div class="invite-record">
        <InviteRecord></InviteRecord>
      </div> -->
      <!-- 活动规则 tag-->
      <!-- <div class="rightRuleTag" @click="ruleShow = true">
        <div class="rightRuleTagContent">活<br />动<br />规<br />则</div>
      </div> -->
      <!-- <div class="rightRuleTag xxxx" @click="xxxx">
        邀<br />请<br />好<br />友
      </div> -->
      <van-popup v-model="ruleShow" closeable round :style="{ width: '80%' }">
        <ActivityRule></ActivityRule>
      </van-popup>
      <van-share-sheet
        v-model="showShare"
        title="分享朋友圈"
        :options="options"
        @click-overlay="onClickCancel"
        @select="onSelect"
      />
      <div></div>
      <img
        class="imgsharethree"
        src="~@/assets/img/invite/overlay_wx.png"
        v-if="shareThree"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import InviteWay from "./components/invite-way.vue";
import ActivityRule from "./components/activity-rule.vue";
import InviteLogin from "./components/invite-login.vue";
import { getURLParamsByKey, bindUserOpenId, getWechatCode } from "@/utils/index";
import { portalForward } from "@/api/invite";
export default Vue.extend({
  components: { ActivityRule, InviteLogin },
  data() {
    return {
      showShare: false,
      options: [{ name: "朋友圈", icon: "wechat-moments" }],
      ruleShow: false,
      inviterMobile: "",
      invitationMethod: "0",
      shareThree: false,
    };
  },
  mounted() {
    localStorage.removeItem("completeService");
    this.$store.commit("SET_COMPLETE_SERVICE", "false");
    document.title = "公测赢福利  纵享三重礼";
    this.inviterMobile = this.$route.query?.inviterMobile;
    if ((this.$store.getters.inviteToken ?? "") !== "") {
      this.$router.push({
        path: `/openBetaShareEwm?inviterMobile=${this.$store.getters.currentLoginMobile}`,
      });
    }
  },
  methods: {
    BindUserOpenId() {
      // let currentUrl = window.location.href;
      // const ua = window.navigator.userAgent.toLowerCase();
      // let code = getURLParamsByKey("code") || "";
      // // localStorage.setItem("wxCode", code);
      // // this.$store.commit("SET_WXCODE", code);
      // if (code == "" && (this.$store.getters.wxcode || "") !== "") {
      //   bindUserOpenId(
      //     ua,
      //     currentUrl,
      //     this.$store.getters.wxcode,
      //     this.$store.getters.inviteToken,
      //     this.$router
      //   );
      // } else if (code == "") {
      //   getWechatCode(ua, currentUrl, this.$router);
      // } else if (code !== "") {
      //   localStorage.setItem("wxCode", code);
      //   this.$store.commit("SET_WXCODE", code);
      //   console.log(this.$router);
      //   this.$router.go(-1);
      // }
    },
    xxxx() {
      this.showShare = true;
    },
  },
});
</script>
<style lang="less" scoped>
@keyframes rightRuleTagContent {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(5px);
  }
}

.rightRuleTagContent {
  animation: rightRuleTagContent 0.5s; /* 抖动的持续时间 */
  animation-iteration-count: 1; /* 抖动的次数，这里设置为无限次 */
}

.rightRuleTag {
  position: fixed;
  z-index: 5;
  top: 220px;
  right: 0;
  width: 26px;
  min-height: 70px;
  background: linear-gradient(-15deg, #deeefd, #ffffff);
  padding: 6px 0;
  box-shadow: 0px 1px 9px 2px rgba(0, 0, 0, 0.16);
  text-align: center;
  border-radius: 5px 0 0 5px;
  font-weight: bold;
  font-size: 12px;
  color: #4494f7;
  line-height: 15px;
}
.rightRuleTag.xxxx {
  top: 300px;
  color: orangered;
}
.invite {
  width: 100%;
  position: relative;
  min-height: 100vh;
  background: url("~@/assets/img/invite/open-beta-invite_bg.png") center top no-repeat;
  background-size: 100%;
  background-color: #3ba6fc;
  &-bg {
    &-img {
      width: 100%;
    }
  }
  .content {
    position: relative;
    padding-top: 180px;
    z-index: 27;
    .invite-record {
      margin-top: 20px;
    }
    .activity-rule {
      margin-top: 20px;
    }
  }
}
.acc-num {
  font-size: 11px;
  color: #fff;
  position: absolute;
  right: 15px;
  top: 20px;
  z-index: 22;
}
.imgsharethree {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2099;
  width: 70%;
}
</style>
